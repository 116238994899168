<template>
  <div class="pageContol classReviewDetail">
    <div class="framePage">
      <!-- <div class="framePage-title">
      </div> -->
      <div class="framePage-body">
        <div class="ovy-a">
          <h3 style="padding-bottom:20px;">课程基本信息</h3>
          <div>
            <el-form :inline="true" ref="ruleForm" label-width="10rem">
              <el-form-item label="课程名称：" class="form-inline">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.trainTypeNamePath"
                label="培训类型："
                class="form-inline"
              >
                <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.industryNamePath"
                label="行业类型："
                class="form-inline"
              >
                <p>{{ ruleForm.industryNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.postName"
                label="岗位类型："
                class="form-inline"
              >
                <p>{{ ruleForm.postName || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.occupationNamePath"
                label="职业/工种："
                class="form-inline"
              >
                <p>{{ ruleForm.occupationNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.trainLevelName"
                label="培训等级："
                class="form-inline"
              >
                <p>{{ ruleForm.trainLevelName || "" }}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  v-html="ruleForm.context"
                  ref="editor"
                  style="width:100%;position: relative;z-index: 2;"
                />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div class="img-el-upload" style="width:256px;">
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item label="讲师备注：" prop="lecturer">
                <p>{{ ruleForm.lecturer || "无" }}</p>
              </el-form-item>
            </el-form>
          </div>

          <h3 style="padding-bottom:20px;">总学时设置</h3>
          <div class="lessonNum">
            <span>所选课件总学时：{{ lessonNum }}学时</span>
            <span>必修课总学时：{{ lessonNumMust }}学时</span>
            <span>选修课总学时：{{ lessonNum - lessonNumMust || 0 }}学时</span>
          </div>
          <p class="lessNumcount">
            <span>要求完成总学时：</span>
          
            <el-input-number
              v-model="lessnumcount"
              @change="handleChange"
              size="small"
              style="width:10rem"
              controls-position="right"
              :min="1"
              :precision="0"
              :step="1"
              :disabled="true"
            ></el-input-number>
          </p>
          <h3 style="padding:20px 0;">课程章节</h3>
          <el-tree
            ref="tree"
            :data="data"
            :show-checkbox="true"
            node-key="courseKpointId"
            default-expand-all
            :expand-on-click-node="false"
            :default-checked-keys="this.selects"
            :props="defaultProps"
            @check-change="getChecked"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <template v-if="data.level == 1">
                <p :title="data.catalogName" class="ellipsis">
                  {{ data.catalogName }}
                </p>
              </template>
              <template v-else-if="data.level == 2">
                <!-- <div> -->
                <span
                  v-if="data.list.length > 0"
                  style="display: flex;flex-direction: row;align-items: center;"
                >
                  <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                  <i class="paperIcon" v-else></i>
                </span>
                <span
                  v-text="data.catalogName"
                  class="ellipsis"
                  :title="data.catalogName"
                ></span>
               
                <el-button
                  type="text"
                  @click="showVideo(data)"
                  size="mini"
                  >预览</el-button
                >
                <el-radio-group
                  v-model="data.requiredState"
                  v-show="stu != 'classReviewDetail'&&data.list[0].kpointId"
                  class="set-food-select"
                  size="mini"
                  value-key="specId"
                  :disabled="true"
                >
                  <el-radio :label="true">必修课</el-radio>
                  <el-radio :label="false">选修课</el-radio>
                </el-radio-group>
              </template>
              <template v-else>
                <span
                  v-text="data.catalogName"
                  :title="data.catalogName"
                  class="ellipsiss"
                  v-if="stu != 'classReviewDetail'"
                ></span>
                <span
                  v-else
                  class="ellipsiss"
                  :title="data.title + ':' + data.catalogName"
                  >{{ data.title }}：{{
                    data.catalogName ||
                      (data.title == "授课讲师" ? "无讲师" : "无习题")
                  }}</span
                >
                <el-button
                  type="text"
                  @click="showPaper(data)"
                  size="mini"
                  v-show="data.paperId"
                  >预览</el-button
                >
                <el-button
                  type="text"
                  @click="show(data)"
                  size="mini"
                  v-show="data.qualificationImageId"
                  class="dataqualificationImageId"
                  >预览</el-button
                >
              </template>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <PaperShow ref="PaperShow" />
         <el-dialog
      :title="'课件名称：' + videoUrl.kpointName"
      :visible="videoUrl.kpointVideoId ? true : false"
      size="tiny"
      width="1200px"
      top="3%"
      :before-close="videoUrlClose"
    >
      <playerH
        v-if="videoUrl.kpointVideoId"
        :videoId="videoUrl.kpointVideoId"
        :kpointId="videoUrl.kpointId"
      />
    </el-dialog>
  </div>
</template>
<script>
let id = 1000;

import playerH from "../DetailResource/player.vue";
import PaperShow from "../DetailResource/paperShow";
export default {
  components: {
    PaperShow,
    playerH
  },
  data() {
    return {
       videoUrl: {
        kpointName: "",
        kpointVideoId: "",
      },
      src: "",
      teacherName: "",
      data: [],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId"
      },
      lessnumcount: "",
      ruleForm: {
        createId: "",
        courseName: "", // 课程名称
        context: "", //课程详情
        thumbnail: "",
        trainTypeNamePath: "", // 培训类型名称树
        postName: "", // 岗位名称
        industrynamePath: "", // 行业名称树
        lecturer: ""
      },
      lessonNum: 0, //课程总学时哦
      lessonNumMust: 0, // 必修学时
      lessonNumDemand: 0, // 要求完成总学时
      selects: [],
      projectId: "",
      courseId: "",
      stu: ""
    };
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.projectId = sessionStorage.getItem("projectId");
    this.lessonNumMust = 0;
    this.$nextTick(() => {
      this.getDetail();
    });
  },

  mounted() {},
  methods: {
    getDetail() {
      this.$post(
        "/biz/heNan/queryH5/getH5CourseInfo", { json: this.$route.query.json}
      ).then(result => {
        const data = result.data || {};
        if (result.status === "0") {
          this.ruleForm = {
            projectCourseId: data.projectCourseBo.projectCourseId,
            courseName: data.editDisplayBO.courseName, // 课程名称
            context: data.editDisplayBO.context, //课程详情
            thumbnail: data.editDisplayBO.logoURL,
            lecturer: data.editDisplayBO.lecturer,
            trainTypeNamePath: data.editDisplayBO.trainTypeNamePath,
            postName: data.editDisplayBO.postName,
            industryNamePath: data.editDisplayBO.industryNamePath,
            occupationNamePath: data.editDisplayBO.occupationNamePath,
            trainLevelName: data.editDisplayBO.trainLevelName
          };
          this.selects = data.selects.map(Number);
          this.lessnumcount = data.projectCourseBo.lessonNumDemand;
          this.lessonNum = data.projectCourseBo.lessonNum;
          this.lessonNumMust = data.projectCourseBo.lessonNumMust;
          this.creatData(data.editDisplayBO);
        }
      });
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapterSchema;
      if (data.chapterSchema) {
        // 样式一 ------> 三层
        data.nodes.forEach(element => {
          let nodeList = [];
          list.push({
            courseKpointId: element.courseKpointId,
            catalogName: element.catalogName,
            parentNodeId: element.parentNodeId,
            children: nodeList,
            level: 1,
            disabled: true 
          });
          (element.child || []).forEach(obj => {
            nodeList.push(this.created2dom(obj));
          });
        });
      } else {
        data.nodes.forEach(element => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
    },
    created2dom(obj) {
      let children = [];
      if (this.stu == "classReviewDetail") {
        if (obj.paperId || obj.teacherPathName) {
          children = [
            {
              title: "课后习题",
              catalogName: obj.paperName,
              courseKpointId: obj.courseKpointId,
              sourceCourseKpointId: obj.courseKpointId,
              level: 3,
              paperId: obj.paperId,
              disabled:true
                 
            },
            {
              title: "授课讲师",
              catalogName: obj.teacherPathName,
              level: 3,
              qualificationImageId: obj.qualificationImageId
            }
          ];
        }
      } else {
        if (obj.paperId) {
          children = [
            {
              title: "课后习题",
              catalogName: obj.paperName,
              courseKpointId: obj.courseKpointId,
              sourceCourseKpointId: obj.courseKpointId,
              level: 3,
              paperId: obj.paperId,
              disabled:true
            }
          ];
        }
      }

      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointId: obj.kpointId
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          paperId: obj.paperId,
          paperName: obj.paperName
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          disabled:true 
        };
      } else {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          requiredState: false,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          disabled: true ,
          paperId: obj.paperId,
          paperName: obj.paperName
        };
      }
    },
   
    getChecked(data, checked, indeterminate) {
      let num = 0;
      let ElectiveNum = 0;
      this.$refs.tree.getCheckedNodes().map((item, index) => {
        if (item.level == 2) {
          if (item.requiredState == true) {
            num += item.kpointLessonNum;
          } else {
           
            let kpointLessonNum=item.kpointLessonNum&&item.kpointLessonNum!=undefined?item.kpointLessonNum:0;
            ElectiveNum += kpointLessonNum;
          }
        }
      });

      this.lessonNum = num + ElectiveNum;
      this.lessonNumMust = num;
      this.lessnumcount =  num + ElectiveNum;
      // if (data.requiredState) {
      //   if (data.requiredState && checked) {
      //     this.lessnumcount += data.kpointLessonNum;
      //   } else if (data.requiredState == false) {
      //     return;
      //   } else {
      //     this.lessnumcount -= data.kpointLessonNum;
      //   }
      // }
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
    /**
     * 保存
     */
    getCheckedNodes() {
      const cClessonNum = sessionStorage.getItem("cClessonNum");
      const projectSource = sessionStorage.getItem("projectSource");
      let checked = [];
      let checkeds = [];
      checked = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      if (this.lessnumcount == "") {
        this.$message({
          message: "请输入要求完成总学时",
          type: "warning"
        });
        return false;
      }
      if (this.lessnumcount > this.lessonNum) {
        this.$message({
          message: "要求完成总学时不能大于所选课件总学时",
          type: "warning"
        });
        return false;
      }
      if (checked.length == 0) {
        this.$message({
          message: "请选择课程编排",
          type: "warning"
        });
        return false;
      }
      if (projectSource == "20") {
        if (
          this.lessonNumDemand != cClessonNum &&
          this.lessonNum != cClessonNum
        ) {
          this.$message({
            message:
              "所选课件总学时和要求完成总学时必须等于规定学时,(" +
              cClessonNum +
              "学时)",
            type: "warning"
          });
        }
      }
      let parmar = {
        lessonNum: this.lessonNum,
        lessonNumDemand: this.lessnumcount,
        lessonNumMust: this.lessonNumMust,
        courseId: this.$route.query.courseId,
        modify: 1,
        projectId: this.projectId,
        selectIds: checked,
        selectNode: this.data,
        projectCourseId: this.$route.query.projectCourseId
      };
      this.$post("/biz/projectCourseOutline/disposeProjectCourse", parmar)
        .then(ret => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success"
            });
            this.$router.back();
          }
        })
        .catch(err => {
          return;
        });
    },
      // 预览
    showVideo(data) {
      //单元测试打开是题库
      console.log(data)
      if (data.list[0].kpointVideoId) {
      this.videoUrl = {
        kpointName: data.catalogName,
        kpointVideoId: data.list[0].kpointVideoId,
      };
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
      
    },
       videoUrlClose() {
      this.videoUrl = {
        kpointName: "",
        kpointVideoId: "",
      };
    },
    
  }
};
</script>
<style lang="less">
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 45%;
  p {
    width: 20rem;
  }
}
.classReviewDetail {
  .lessNumcount {
    .el-icon-arrow-down:before {
      content: "\e6df" !important;
    }
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scoped>
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 39.85rem;
  font-size: 14px;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}
.dataqualificationImageId {
  margin-left: -2px;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
</style>
