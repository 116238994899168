<template>

   <div style="width: 100%; height: 600px;">
    <div class="prism-player" id="J_prismPlayer"></div>
  </div>
 
</template>
<script>
export default {
  props: {
    videoId: {
      type: String,
      default: ""
    },
    kpointId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      let that = this;
      console.log(that.videoId);
      
      //查询节点信息
      that
        .$post("/biz/heNan/queryH5/H5VideoPlayAuth", { videoId: that.videoId })
        .then(result => {
          if (result.status === "0") {
            that.player = new Aliplayer({
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: true,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              //source: "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4",
              //播放方式二：点播用户推荐
              vid: that.videoId,
              playauth: result.data,
              //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
              skinLayout: [
                { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                { name: "infoDisplay" },
                { name: "tooltip", align: "blabs", x: 0, y: 56 },
                { name: "thumbnail" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "blabs", x: 0, y: 44 },
                    { name: "playButton", align: "tl", x: 15, y: 12 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                    { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                    { name: "setting", align: "tr", x: 15, y: 12 },
                    { name: "volume", align: "tr", x: 5, y: 10 }
                  ]
                }
              ]
            });
          } else {
            that.$message.warning({ message: result.message, duration: 1500 });
          }
        });
    },
   
  }
};
</script>

<style lang="scss" scoped></style>
